/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.izletistePolovanec .ant-table-thead > tr > th {
    font-weight: 700;
    background: #81423b;
    font-size: 16px;
    background-color: #81423b;
    color: beige;
}

.izletistePolovanec .ant-table-tbody > tr > td {
  white-space: break-spaces;
}

.izletistePolovanec .cijenaColumn {
  padding-left: 0px;
  padding-right: 6px;
}

.izletistePolovanec-row-light {
    background-color: #fff;
}

.izletistePolovanec-row-dark {
    background-color: #eee;
}

.izletistePolovanec-logo-header {
    margin-left: calc(50vw - 50px);
    width: 100px;
    margin-top: 20px;
    margin-bottom: 10px;
    height: 100px,
}

.izletistePolovanec-logo-footer {
    margin-left: calc(50vw - 50px);
    width: 100px;
    margin-top: 20px;
    height: 100px,
}

.izletistePolovanec-logo-admin-not-logged {
    margin-left: calc(50vw - 50px);
    width: 100px;
    margin-top: 100px;
    height: 100px;
}

.izletistePolovanec-logo-admin-logged {
    margin-left: calc(50vw - 50px);
    width: 100px;
    margin-top: 10px;
    height: 100px;
}

.izletistePolovanec-grid {
    width: 100vw;
    padding: 0;
    margin: 0;
    background-color: white;
}

.izletistePolovanec-header-text {
  color: black;
}

.izletistePolovanec-admin-text {
  color: black;
}

.izletistePolovanec-ant-form {
    min-height: 100vh;
    min-width: 100vw;
    background-color: white;
}

.izletistePolovanec-admin-buttons {
    background: #F0F0F0;
    color: black;
}

.restoranMaksimir .ant-table-thead > tr > th {
    font-weight: 700;
    background:#292929;
    font-size: 16px;
    background-color: #292929;
    color: lightgray;
}

.restoranMaksimir .ant-table-tbody > tr > td {
  white-space: break-spaces;
}

.restoranMaksimir .cijenaColumn {
  padding-left: 0px;
  padding-right: 6px;
}

.restoranMaksimir-row-light {
    background-color: #fff;
}

.restoranMaksimir-row-dark {
    background-color: #eee;
}

.restoranMaksimir-logo-header {
    margin-left: calc(50vw - 50px);
    width: 100px;
    margin-top: 20px;
    margin-bottom: 10px;
    height: 100px,
}

.restoranMaksimir-logo-footer {
    margin-left: calc(50vw - 50px);
    width: 100px;
    margin-top: 20px;
    height: 100px,
}

.restoranMaksimir-logo-admin-not-logged {
    margin-left: calc(50vw - 50px);
    width: 100px;
    margin-top: 100px;
    height: 100px;
}

.restoranMaksimir-logo-admin-logged {
    margin-left: calc(50vw - 50px);
    width: 100px;
    margin-top: 10px;
    height: 100px;
}

.restoranMaksimir-grid {
    width: 100vw;
    padding: 0;
    margin: 0;
    background-color: white;
}

.restoranMaksimir-header-text {
  color: black;
}

.restoranMaksimir-admin-text {
  color: black;
}

.restoranMaksimir-ant-form {
    min-height: 100vh;
    min-width: 100vw;
    background-color: white;
}

.restoranMaksimir-admin-buttons {
    background: #F0F0F0;
    color: black;
}

.test .ant-table-thead > tr > th {
    font-weight: 700;
    background: grey;
    font-size: 16px;
    background-color: grey;
    color: beige
}

.test .ant-table-tbody > tr > td {
  white-space: break-spaces;
}

.test .cijenaColumn {
  padding-left: 0px;
  padding-right: 6px;
}

.test-row-light {
    background-color: #fff;
}

.test-row-dark {
    background-color: #eee;
}

.test-logo-header {
    margin-left: calc(50vw - 50px);
    width: 100px;
    margin-top: 20px;
    margin-bottom: 10px;
    height: 100px,
}

.test-logo-footer {
    margin-left: calc(50vw - 50px);
    width: 100px;
    margin-top: 20px;
    height: 100px,
}

.test-logo-admin-not-logged {
    margin-left: calc(50vw - 50px);
    width: 100px;
    margin-top: 100px;
    height: 100px;
}

.test-logo-admin-logged {
    margin-left: calc(50vw - 50px);
    width: 100px;
    margin-top: 10px;
    height: 100px;
}

.test-grid {
    width: 100vw;
    padding: 0;
    margin: 0;
    background-color: white;
}

.test-header-text {
  color: black;
}

.test-admin-text {
  color: black;
}

.test-ant-form {
    min-height: 100vh;
    min-width: 100vw;
    background-color: white;
}

.test-admin-buttons {
    background: #F0F0F0;
    color: black;
}

.shockBar .ant-table-thead > tr > th {
    font-weight: 700;
    background: black;
    font-size: 16px;
    background-color: black;
    color: white;
}

.shockBar .ant-table-tbody > tr > td {
    white-space: break-spaces;
}

.shockBar .cijenaColumn {
    padding-left: 0px;
    padding-right: 6px;
}

.shockBar-row-light {
    background-color: #fff;
}

.shockBar-row-dark {
    background-color: #eee;
}

.shockBar-logo-header {
    margin-left: calc(50vw - 150px);
    width: 300px;
    margin-top: 20px;
    margin-bottom: 10px;
    height: 70px,
}

.shockBar-logo-footer {
    margin-left: calc(50vw - 150px);
    width: 300px;
    margin-top: 20px;
    height: 70px,
}

.shockBar-logo-admin-not-logged {
    margin-left: calc(50vw - 150px);
    width: 300px;
    margin-top: 100px;
    height: 70px;
}

.shockBar-logo-admin-logged {
    margin-left: calc(50vw - 150px);
    width: 300px;
    margin-top: 10px;
    height: 70px;
}

.shockBar-grid {
    width: 100vw;
    padding: 0;
    margin: 0;
    background-color: black;
}

.shockBar-header-text {
  color: white;
}

.shockBar-admin-text {
  color: white;
}

.shockBar-ant-form {
    min-height: 100vh;
    min-width: 100vw;
    background-color: black;
}

.shockBar-admin-buttons {
    background: black;
    color: white;
}

.row-white {
    background-color: #fff;
}

.row-grey {
    background-color: #eee;
}

.ant-layout {
    background-color: #fff;
    align-items: center;
}

.ant-form-item-label {
  white-space: normal;
}

.ant-spin-dot-item {
  background-color: grey;
}

.ant-form-item-label-language-currency{
  display: contents;
  width: 70px;
  margin-left: 100px;
} 